import baseAxios from '@/api/base';

// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** News api */
/**
 * 首頁最新消息列表
 * @param {object} data { categoryCount, newsCount }
 */
export const GetHomeNews = (data) => baseAxios.post('/FrontEnd/News/HomeNews', data);

/**
 * 前端-取得最新消息列表
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {
    "newsCategoryId": 0
  }
  }
*/
export const GetNewsList = (data) => baseAxios.post('/FrontEnd/News/News', data);

/**
 * 取得最新消息資料
 * @param {object} data { id }
 */
export const GetNewsById = (id) => baseAxios.post('/FrontEnd/News/NewsById', { id });

/**
 * 所有已啟用最新消息分類
 * @param {object} data { }
 */
export const GetNewsCategoryAll = () => baseAxios.post('/FrontEnd/NewsCategory/NewsCategoryAll');
