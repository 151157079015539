<template>
  <section class="container pb-8">
    <div class="cs-table" data-style="1">
      <div class="border-shadow mb-6">
        <h3
          class="table__title bg-info text-dark"
          :data-date="NewsData.newsStartDate | toTaipei | moment('YYYY-MM-DD')"
        >
          {{ NewsData.newsSubject }}
        </h3>
        <div class="table__body bg-light edit">
          <p v-html="NewsData.newsContent"></p>
          <a v-if="NewsData.newsLink" :href="NewsData.newsLink" target="_blank">相關連結(另開)</a>
        </div>
      </div>
    </div>
    <!-- 返回 -->
    <div class="d-flex justify-content-end">
      <router-link class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3" to="/news">
        返回列表
      </router-link>
    </div>
  </section>
</template>

<script>
import { GetNewsById } from '@/api/NewsApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      NewsData: {
        newsCategoryId: 1,
        newsContent: '',
        newsEndDate: null,
        newsId: 0,
        newsLink: '',
        newsStartDate: null,
        newsSubject: '',
        noticeDate: null,
        status: 0,
      },
    };
  },
  created() {
    this.GetNews();
  },
  methods: {
    GetNews() {
      // 取得 url id 的方法
      const { id } = this.$route.params;
      GetNewsById(id).then((response) => {
        if (response.data.statusCode === 0) {
          this.NewsData = response.data.data;
          this.$emit('update:currentPage', {
            name: '',
            title: this.NewsData.newsSubject,
            path: '',
          });
        } else if (response.data.statusCode === 7920008) {
          this.$router.replace('/');
        }
      });
    },
  },
};
</script>

<style>
pre {
  white-space: break-spaces;
}
</style>
